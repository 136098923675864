<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">F.A.Q.</h3>
        </div>
      </div>
      <div class="card-body">
        <v-expansion-panels>
          <v-expansion-panel v-for="(faq, i) in faq_list" :key="i">
            <v-expansion-panel-header>
              <b>{{ faq.faq_question }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ faq.faq_answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <!--end::Employees-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
  name: "FaqList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      faq_list: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    let context = this;

    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "F.A.Q." }]);
    context.getFaqs();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getFaqs() {
      let context = this;
      axios({
        method: "post",
        url: "get-faqs",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.faqs) {
            result.data.faqs.forEach(faq => {
              context.faq_list.push({
                faq_question: faq.question,
                faq_answer: faq.answer
              });
            });
          } else {
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          Swal.fire("Error", "Faq fetch error!", "error");
        }
      );
    }
  }
};
</script>
